import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'

import 'antd/dist/antd.css'
import './src/assets/style/reset.scss'
import './src/assets/style/variables-css.scss'

import './src/assets/style/common.scss'
import './src/assets/style/global.scss'

import React from 'react'
import fetch from 'cross-fetch'
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider } from '@apollo/client'
const cache = new InMemoryCache()
const link = new HttpLink({
    uri: process.env.GATSBY_APOLLO_URL || 'https://admin.getresidency.eu/graphql',
    fetch,
})
const client = new ApolloClient({
    link,
    cache,
})
export const wrapRootElement = ({ element }) => <ApolloProvider client={client}>{element}</ApolloProvider>
